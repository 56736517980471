import { nbsp } from '@orus.eu/char'
import {
  subscriptionAcceptedDocumentsDescription,
  type SubscriptionDocumentType,
  type SubscriptionDocumentValidationStatus,
} from '@orus.eu/dimensions'
import { colorTokens } from '../../../../foundation'
import type { ChipVariant } from '../../../chip'
import type { CollectedFileStatus } from '../types'
import attestationDecennaleExampleImageSrc from './images/attestation-decenale.png'
import experienceCertificateExampleImageSrc from './images/experience-certificate.png'
import experiencePayslipExampleImageSrc from './images/experience-payslip.png'
import experiencePizzeriaCertificateExampleImageSrc from './images/experience-pizzeria-certificate.png'
import experiencePizzeriaPayslipExampleImageSrc from './images/experience-pizzeria-payslip.png'
import factureJustificativeExampleImageSrc from './images/facture-justificative.png'
import factureViergeExampleImageSrc from './images/facture-vierge.png'
import idExampleImageSrc from './images/id.png'
import immatriculationINPIExampleImageSrc from './images/immatriculation-inpi.png'
import immatriculationINSEEExampleImageSrc from './images/immatriculation-insee.png'
import immatriculationKBISExampleImageSrc from './images/immatriculation-kbis.png'
import releveInformationExampleImageSrc from './images/releve-information.png'
import ribExampleImageSrc from './images/rib.png'
import yearlyAccountExampleImageSrc from './images/yearly-account.png'

export const subscriptionFunnelDocumentStatusText: Record<SubscriptionDocumentValidationStatus, string> = {
  none: 'Non validé',
  incomplete: 'À compléter',
  insuranceReview: 'Envoyé',
  pending: 'Non validé',
  received: 'Envoyé',
  rejected: 'À compléter',
  validated: 'Validé',
}

export const subscriptionDocumentStatusText: Record<SubscriptionDocumentValidationStatus, string> = {
  none: 'None',
  incomplete: 'Insuffisant',
  insuranceReview: 'Attente review assurance',
  pending: 'En cours de réception',
  received: 'Reçu',
  rejected: 'Refus',
  validated: 'Validé',
}

export const subscriptionDocumentStatusSubtitleText: Record<SubscriptionDocumentValidationStatus, string> = {
  none: 'Aucun document importé.',
  incomplete: 'Des documents sont manquants.',
  insuranceReview: 'À faire vérifier par les souscripteurs décennale.',
  pending: 'En attente de reception de documents',
  received: 'De nouveaux documents sont à vérifier.',
  rejected: 'L’ensemble des pièces a été refusé.',
  validated: 'Tous les documents sont conformes.',
}

export const collectedFileStatusConfig: Record<
  CollectedFileStatus,
  { text: string; subtitle: string; variant: ChipVariant }
> = {
  new: {
    text: 'À valider',
    subtitle: 'Ce fichier est nouveau.',
    variant: 'neutral',
  },
  'new-revised': {
    text: 'Révisé',
    subtitle: 'Ce fichier est nouveau mais a déjà été vu par les souscripteurs dans une autre version.',
    variant: 'warning',
  },
  rejected: {
    text: 'Rejeté',
    subtitle: 'Ce fichier a été refusé.',
    variant: 'danger',
  },
  validated: {
    text: 'Validé',
    subtitle: 'Ce fichier est conforme.',
    variant: 'success',
  },
  ignored: {
    text: 'Ignoré',
    subtitle: 'Ce fichier a été ignoré.',
    variant: 'informal',
  },
}

export const collectedFileStatusLabels = Object.fromEntries(
  Object.entries(collectedFileStatusConfig).map(([status, config]) => [status, config.text]),
)

export const subscriptionDocumentBackgroundColor: Record<SubscriptionDocumentValidationStatus, string> = {
  none: colorTokens['color-bg-warning-inverse'],
  incomplete: colorTokens['color-bg-warning-inverse'],
  insuranceReview: colorTokens['color-bg-info-inverse'],
  pending: colorTokens['color-bg-warning-inverse'],
  received: colorTokens['color-bg-info-inverse'],
  rejected: colorTokens['color-bg-warning-inverse'],
  validated: colorTokens['color-bg-success-inverse'],
}

export const subscriptionDocumentTextColor: Record<SubscriptionDocumentValidationStatus, string> = {
  none: colorTokens['color-text-warning-primary'],
  incomplete: colorTokens['color-text-warning-primary'],
  insuranceReview: colorTokens['color-text-info-primary'],
  pending: colorTokens['color-text-warning-primary'],
  received: colorTokens['color-text-info-primary'],
  rejected: colorTokens['color-text-warning-primary'],
  validated: colorTokens['color-text-success-primary'],
}

export type Example = {
  name?: string
  imageSrc: string
}

export type SubscriptionDocumentsConfiguration = {
  uploadDialogTitle: string
  acceptedAlternativeDocuments?: string[]
  acceptedDocuments?: string[]
  requiredConditions?: string[]
  requiredAlternativeConditions?: string[]
  additionalExplanations?: string
  examples?: Example[]
  examplesWidth?: string
  highlights?: string[]
  callout?: string
}

export const subscriptionDocumentsConfiguration: Record<SubscriptionDocumentType, SubscriptionDocumentsConfiguration> =
  {
    id: {
      uploadDialogTitle: 'Importer votre pièce d’identité',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['id'],
      requiredConditions: ['Recto et verso (sauf passeport).', 'En cours de validité uniquement.'],
      additionalExplanations: 'Ce document nous permet de vérifier votre identité en tant que demandeur d’attestation.',
      examples: [
        {
          imageSrc: idExampleImageSrc,
        },
      ],
    },
    immatriculation: {
      uploadDialogTitle: 'Importer votre document d’immatriculation',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['immatriculation'],
      requiredConditions: ['Document daté de moins de 90 jours.'],
      additionalExplanations:
        'Ce document nous permet de retracer de façon officielle votre parcours et d’avoir les informations clées relatives à votre entreprise.',
      examples: [
        {
          name: 'Extrait KBIs',
          imageSrc: immatriculationKBISExampleImageSrc,
        },
        {
          name: 'Fiche INSEE',
          imageSrc: immatriculationINSEEExampleImageSrc,
        },
        {
          name: 'Fiche INPI',
          imageSrc: immatriculationINPIExampleImageSrc,
        },
      ],
    },
    infoReport: {
      uploadDialogTitle: 'Importer votre relevé d’information',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['infoReport'],
      requiredConditions: ['Document daté de moins de 90 jours.'],
      additionalExplanations:
        'Ce document nous permet de comprendre vos antécédents d’assurances et motifs de résiliation.',
      examples: [
        {
          imageSrc: releveInformationExampleImageSrc,
        },
      ],
      highlights: ['Date d’effet du contrat', 'Date et motif de résiliation', 'Historique de sinistralité'],
      examplesWidth: '44%',
    },
    proformaInvoice: {
      uploadDialogTitle: 'Importer votre facture vierge',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['proformaInvoice'],
      requiredConditions: ['Figurant vos informations de contact et votre numéro SIRET/SIREN valide.'],
      additionalExplanations:
        'Ce document nous permet de vérifier les informations relatives à votre entreprise sur une facture.',
      examples: [
        {
          imageSrc: factureViergeExampleImageSrc,
        },
      ],
      examplesWidth: '44%',
      highlights: ['Informations de contact', 'Numéro de SIRET/SIREN en cours d’activité'],
    },
    invoices: {
      uploadDialogTitle: 'Importer vos factures justificatives',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['invoices'],
      requiredConditions: ['5 factures sur les 4 derniers trimestres.'],
      additionalExplanations:
        'Ce document nous permet de vérifier que vous effectuez précisément les travaux pour lesquels vous souhaitez être assuré.',
      examples: [
        {
          imageSrc: factureJustificativeExampleImageSrc,
        },
      ],
      examplesWidth: '44%',
      highlights: [
        'Informations de contact',
        'Numéro de SIRET/SIREN en cours d’activité',
        'Détail du type de travaux réalisés',
      ],
    },
    RIB: {
      uploadDialogTitle: 'Importer votre RIB',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['RIB'],
      additionalExplanations: `Ce document nous permet de connaitre votre domiciliation bancaire pour recevoir des virements et${nbsp}/${nbsp}ou régler des factures par avis de prélèvement.`,
      examples: [
        {
          imageSrc: ribExampleImageSrc,
        },
      ],
      examplesWidth: '52%',
    },
    experience: {
      uploadDialogTitle: 'Importer votre justificatif d’expérience',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['experience'],
      acceptedAlternativeDocuments: ['5 factures étalées sur les 4 derniers trimestres.'],
      requiredConditions: [
        'Documents attestant de l’ancienneté et l’intitulé de poste (le plus précisément possible).',
      ],
      requiredAlternativeConditions: ['5 factures sur les 4 derniers trimestres.'],
      additionalExplanations:
        'Ce document nous permet de confirmer votre ancienneté dans le poste précis pour lequel vous déclarez votre expérience.',
      examples: [
        {
          name: 'Certificat de travail',
          imageSrc: experienceCertificateExampleImageSrc,
        },
        {
          name: 'Bulletin de paie',
          imageSrc: experiencePayslipExampleImageSrc,
        },
      ],
      highlights: ['L’intitulé précis du poste occupé', 'Ancienneté (date de début & date de fin)'],
      callout:
        'Des justificatifs complémentaires en lien avec l’activité exercée vous seront demandés en cas d’intitulé de poste trop large (“Ouvrier” ou “Chef de chantier”).',
    },
    experiencePizzeria: {
      uploadDialogTitle: 'Importer votre justificatif d’expérience',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['experiencePizzeria'],
      requiredConditions: ['Figurant l’ancienneté et l’intitulé de poste (le plus précisément possible).'],
      additionalExplanations:
        'Ce document nous permet de confirmer votre ancienneté dans le poste précis pour lequel vous déclarez votre expérience.',
      examples: [
        {
          name: 'Certificat de travail',
          imageSrc: experiencePizzeriaCertificateExampleImageSrc,
        },
        {
          name: 'Bulletin de paie',
          imageSrc: experiencePizzeriaPayslipExampleImageSrc,
        },
      ],
      highlights: ['L’intitulé précis du poste occupé', 'Ancienneté (date de début & date de fin)'],
      callout:
        'Des justificatifs complémentaires en lien avec l’activité exercée vous seront demandés en cas d’intitulé de poste trop large (“Restauration”).',
    },
    yearlyAccount: {
      uploadDialogTitle: 'Importer vos comptes annuels',
      requiredConditions: ['Bilan comptable ou déclaration URSSAF.'],
      additionalExplanations:
        'Ce document nous permet de vérifier la déclaration officielle des revenus de votre société.',
      examples: [
        {
          imageSrc: yearlyAccountExampleImageSrc,
        },
      ],
      examplesWidth: '44%',
    },
    decennaleCertificate: {
      uploadDialogTitle: 'Importer votre attestation',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['decennaleCertificate'],
      additionalExplanations:
        'Ce document nous permet de connaître les activités pour lesquelles vous avez été assuré par le passé.',
      examples: [
        {
          imageSrc: attestationDecennaleExampleImageSrc,
        },
      ],
      examplesWidth: '44%',
    },
    other: {
      uploadDialogTitle: 'Importer d’autres documents',
      acceptedDocuments: subscriptionAcceptedDocumentsDescription['other'],
    },
  }
