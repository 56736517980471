import { colorTokens, type BadgeVariant } from '@orus.eu/pharaoh'

export type DetailedSheetCompleteStatus = 'pending' | 'accepted' | 'rejected'

export const detailedSheetCompleteStatusText: Record<DetailedSheetCompleteStatus, string> = {
  pending: 'En cours',
  rejected: 'Refusée',
  accepted: 'Acceptée',
}

export const detailedSheetCompleteStatusBadgeVariantColor: Record<DetailedSheetCompleteStatus, BadgeVariant> = {
  pending: 'neutral',
  rejected: 'red',
  accepted: 'green',
}

export const detailedSheetCompleteStatusBackgroundColor: Record<DetailedSheetCompleteStatus, string> = {
  pending: colorTokens['color-bg-warning-inverse'],
  rejected: colorTokens['color-bg-warning-inverse'],
  accepted: colorTokens['color-bg-success-inverse'],
}

export const detailedSheetCompleteStatusTextColor: Record<DetailedSheetCompleteStatus, string> = {
  pending: colorTokens['color-text-warning-primary'],
  rejected: colorTokens['color-text-warning-primary'],
  accepted: colorTokens['color-text-success-primary'],
}
