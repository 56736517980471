import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { type SubscriptionDocumentType } from '@orus.eu/dimensions'
import { memo, useCallback, useEffect, useState, type ChangeEvent } from 'react'
import { colorTokens } from '../../../../foundation/color-tokens'
import { spacing } from '../../../../foundation/spacing-tokens'
import { timeAgo } from '../../../../lib/time'
import { useTranslate } from '../../../../localization'
import { Icon } from '../../../atoms'
import { Text } from '../../../atoms/text'
import { Textarea } from '../../../atoms/textarea'
import { Chip } from '../../../chip'
import { Select, TextField } from '../../../inputs'
import { RowContainerV2 } from '../../../molecules'
import { Tooltip } from '../../../tooltip'
import type { SubscriptionDocumentNoteValue } from '../subscription-document-note-dialog/types'
import {
  collectedFileStatusConfig,
  collectedFileStatusLabels,
  subscriptionDocumentsConfiguration,
} from '../subscription-document-upload-dialog/subscription-document-text'
import type { CollectedFileStatus } from '../types'

type DocumentViewerMenuProps = {
  documentType: SubscriptionDocumentType | null
  allowedCategories: SubscriptionDocumentType[]
  allowedCategoriesLabels: Record<SubscriptionDocumentType, string>
  filesLength: number
  fileIndex: number
  title: string
  documentStatus: CollectedFileStatus
  onStatusChange: (newStatus: string | null) => void
  onCategoryChange: (newCategory: string | null) => void
  onTitleChange: (title: string) => void
  onFileNotesChange: (note: string) => void
  onGroupNotesChange: ((note: string) => void) | null
  fileNotes: SubscriptionDocumentNoteValue
  groupNotes: SubscriptionDocumentNoteValue
  fileDate: Date
}

export const DocumentViewerMenu = memo<DocumentViewerMenuProps>(function DocumentViewerMenu({
  fileIndex,
  filesLength,
  title,
  allowedCategories,
  allowedCategoriesLabels,
  documentStatus,
  onStatusChange,
  onCategoryChange,
  onTitleChange,
  onFileNotesChange,
  onGroupNotesChange,
  fileNotes,
  groupNotes,
  fileDate,
  documentType,
}) {
  const translate = useTranslate()
  const updatableStatus = Object.keys(collectedFileStatusConfig)
  const acceptedAndRequiredConditions = documentType ? subscriptionDocumentsConfiguration[documentType] : undefined

  const [localFileNotes, setLocalFileNotes] = useState<string | null>(fileNotes?.value ?? null)
  const [localGroupNotes, setLocalGroupNotes] = useState<string | null>(groupNotes?.value ?? null)

  const handleFileLocalNotesChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalFileNotes(event.target.value)
  }, [])

  const handleGroupLocalNotesChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalGroupNotes(event.target.value)
  }, [])

  const [localTitle, setLocalTitle] = useState<string | undefined>()

  useEffect(() => {
    setLocalTitle(title)
  }, [title])

  useEffect(() => {
    setLocalFileNotes(fileNotes?.value ?? null)
  }, [fileNotes])

  useEffect(() => {
    setLocalGroupNotes(groupNotes?.value ?? null)
  }, [groupNotes])

  return (
    <SidebarContainer>
      <RowContainerV2 noContainedBorder>
        <SubSectionContainer>
          <FileHeaderContainer>
            <Text variant="subtitle2">Fichier </Text>
            <Chip
              size="small"
              textColor={colorTokens['color-text-base-secondary']}
              backgroundColor={colorTokens['color-bg-neutral-inverse']}
            >
              {translate('x_of_y', { x: fileIndex, y: filesLength })}
            </Chip>
          </FileHeaderContainer>
          <FileNameAndTimestampContainer>
            <TextField
              size="small"
              placeholder="Nom du fichier"
              onChange={(e) => setLocalTitle(e.target.value)}
              onBlur={(e) => onTitleChange(e.target.value)}
              value={localTitle}
            />

            <Text css={css({ marginTop: spacing['40'] })} variant="body2">
              {timeAgo(fileDate)}
            </Text>
          </FileNameAndTimestampContainer>
        </SubSectionContainer>
        <SubSectionContainer>
          <FileHeaderContainer>
            <Text variant="subtitle2">Détails de la pièce</Text>
          </FileHeaderContainer>
          <DocumentCategoryContainer>
            <Select
              aria-label="Catégorie de la pièce"
              value={documentType}
              values={allowedCategories}
              onChange={onCategoryChange}
              size="small"
              placeholder="Catégorie"
              labels={allowedCategoriesLabels}
            />
          </DocumentCategoryContainer>
          <DocumentStatusContainer>
            <DocumentStatusLabelContainer>
              <Chip dark size="small" variant={collectedFileStatusConfig[documentStatus].variant}>
                {collectedFileStatusConfig[documentStatus].text}
              </Chip>
              <Text variant="body2">{collectedFileStatusConfig[documentStatus].subtitle}</Text>
            </DocumentStatusLabelContainer>
            <Select
              aria-label="Statut de validation du fichier"
              value={documentStatus}
              values={updatableStatus}
              onChange={onStatusChange}
              size="small"
              placeholder="Statut"
              labels={collectedFileStatusLabels}
            />
          </DocumentStatusContainer>
          <DocumentInfoRowContainer>
            {acceptedAndRequiredConditions?.acceptedDocuments ? (
              <DocumentInfoRow>
                <Text variant="body2Medium">Documents acceptés</Text>
                <Tooltip title={acceptedAndRequiredConditions.acceptedDocuments.join(' ')}>
                  <Icon icon="circle-info-regular" size="30" />
                </Tooltip>
              </DocumentInfoRow>
            ) : undefined}
            {acceptedAndRequiredConditions?.requiredConditions ? (
              <DocumentInfoRow>
                <Text variant="body2Medium">Conditions requises</Text>
                <Tooltip title={acceptedAndRequiredConditions.requiredConditions.join(' ')}>
                  <Icon icon="circle-info-regular" size="30" />
                </Tooltip>
              </DocumentInfoRow>
            ) : undefined}
          </DocumentInfoRowContainer>
        </SubSectionContainer>
        <SubSectionContainer>
          <FileHeaderContainer>
            <Text variant="subtitle2">Notes de fichier</Text>
          </FileHeaderContainer>

          <Textarea
            size="small"
            placeholder="Saisir une note"
            onChange={handleFileLocalNotesChange}
            onBlur={() => {
              if (localFileNotes != null) onFileNotesChange(localFileNotes)
            }}
            value={localFileNotes ?? ''}
            minRows={3}
            maxRows={5}
          />
          {fileNotes?.updateDate ? (
            <Text
              variant="caption"
              color={colorTokens['color-text-base-basic']}
              css={css`
                margin-top: ${spacing['20']};
              `}
            >
              {timeAgo(fileNotes.updateDate)}
            </Text>
          ) : null}

          {onGroupNotesChange ? (
            <>
              <FileHeaderContainer>
                <Text variant="subtitle2">Notes de groupe</Text>
              </FileHeaderContainer>

              <Textarea
                size="small"
                placeholder="Saisir une note"
                onChange={handleGroupLocalNotesChange}
                onBlur={() => {
                  if (localGroupNotes != null) onGroupNotesChange(localGroupNotes)
                }}
                value={localGroupNotes ?? ''}
                minRows={3}
                maxRows={5}
              />
              {groupNotes?.updateDate ? (
                <Text
                  variant="caption"
                  color={colorTokens['color-text-base-basic']}
                  css={css`
                    margin-top: ${spacing['20']};
                  `}
                >
                  {timeAgo(groupNotes.updateDate)}
                </Text>
              ) : null}
            </>
          ) : null}
        </SubSectionContainer>
      </RowContainerV2>
    </SidebarContainer>
  )
})

const SidebarContainer = styled.div`
  overflow-y: scroll;
  width: 380px;
`

const SubSectionContainer = styled('div')`
  display: flex;
  padding: ${spacing['60']};
  flex-direction: column;
  align-items: left;
  gap: ${spacing['50']};
  align-self: stretch;

  :not(:first-of-type) {
    border-top: 1px solid ${colorTokens['color-stroke-base']};
  }
`
const FileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing['30']};
  align-self: stretch;
`

const FileNameAndTimestampContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`
const DocumentStatusLabelContainer = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['30']};
  align-self: stretch;
`

const DocumentCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['50']};
  align-self: stretch;
`

const DocumentStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['50']};
  align-self: stretch;
`
const DocumentInfoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const DocumentInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: none;
  background: none;
  padding: ${spacing['30']} 0;
`
