import { css } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import styled from '@emotion/styled'
import { TextareaAutosize } from '@mui/material'
import { memo, useMemo, type ChangeEvent } from 'react'
import { colorTokens } from '../../../foundation/color-tokens.js'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { useUiContext, type UiContext } from '../../../hooks/use-screen-variant.js'
import { shouldForwardProp } from '../../../styled.js'
import { Tooltip } from '../../tooltip/tooltip.js'
import { Avatar } from '../avatar/avatar.js'
import { FlexColumn, FlexRow } from '../container.js'
import { cssPropsPerScreenVariantPerTextVariant } from '../text/css-props.js'
import { Text } from '../text/text.js'

export type TextareaSize = 'small' | 'large'
export type DropdownVariant = 'mobile' | 'desktop'

export type TextareaProps = {
  minRows: number
  maxRows: number
  placeholder?: string
  size: TextareaSize
  value: string
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  label?: string
  tooltip?: string
  labelAndTooltipGlued?: boolean
  disabled?: boolean
  helperText?: string
  error?: boolean

  id?: string
  className?: string
  ['aria-label']?: string
}

export const Textarea = memo<TextareaProps>(function Textarea(props) {
  const {
    minRows,
    maxRows,
    placeholder = '',
    disabled,
    size,
    id,
    className,
    'aria-label': ariaLabel,
    label,
    tooltip,
    helperText,
    error = false,
    labelAndTooltipGlued = false,
    onChange,
    onBlur,
    value,
  } = props

  const screenVariant = useUiContext()
  // We need to generate a random id if none is provided to match the label with the input
  const computedId = useMemo(() => id ?? crypto.randomUUID(), [id])

  return (
    <Container
      css={css`
        width: 100%;
      `}
    >
      {(label || tooltip) && (
        <LabelAndOrTooltip inputId={computedId} label={label} tooltip={tooltip} glued={labelAndTooltipGlued} />
      )}

      <Input
        aria-label={ariaLabel}
        id={computedId}
        className={className}
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        placeholder={placeholder}
        $size={size}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        $error={error}
        $screenVariant={screenVariant}
      />

      {helperText && <Text variant="caption">{helperText}</Text>}
    </Container>
  )
})

const Input = styled(TextareaAutosize, { shouldForwardProp })<{
  $error: boolean
  $size: TextareaSize
  $screenVariant: UiContext
}>(
  ({ $error, $size, $screenVariant }) => css`
    padding: ${$size === 'large' ? spacing['40'] : spacing['30']};

    ${cssPropsPerScreenVariantPerTextVariant['input'][$screenVariant]}
    color: ${colorTokens['color-text-base-main']};
    outline: none;

    border-radius: ${spacing['30']};
    border: none;

    /* We use box-shadow instead of border to avoid the border width shifting the content */
    box-shadow: 0 0 0 1px ${colorTokens['color-stroke-base']};
    background: ${colorTokens['color-bg-base-normal']};
    resize: none;

    &::placeholder {
      color: ${colorTokens['color-text-base-disable']};
    }

    ${$error
      ? css`
          box-shadow: 0 0 0 2px ${colorTokens['color-stroke-danger']};
        `
      : css`
          &:hover {
            box-shadow: 0 0 0 1px ${colorTokens['color-stroke-base-hover']};
          }

          &:focus {
            box-shadow:
              0 0 0 2px ${colorTokens['color-stroke-base-selected']} inset,
              0 0 0 2px #dbdcfe;
          }
        `}

    &:disabled {
      color: ${colorTokens['color-text-base-disable']};

      box-shadow: 0 0 0 1px ${colorTokens['color-stroke-base-disable']};
      background: ${colorTokens['color-bg-base-disable']};
    }
  `,
)

const Container = styled(FlexColumn)`
  gap: ${spacing['20']};
`

const LabelAndOrTooltip = memo<{
  inputId: string
  label?: string
  tooltip?: string
  /** Whether the label and tooltip are next to each other or with space between */
  glued: boolean
}>(function LabelAndOrTooltip({ inputId, label, tooltip, glued }) {
  return (
    <LabelAndOrTooltipContainer $glued={glued}>
      {label && (
        <Label variant="body2Medium" element="label" htmlFor={inputId}>
          {label}
        </Label>
      )}

      {tooltip && (
        <Tooltip title={tooltip}>
          <Avatar icon="circle-info-regular" size="30" />
        </Tooltip>
      )}
    </LabelAndOrTooltipContainer>
  )
})

const Label = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LabelAndOrTooltipContainer = styled(FlexRow, { shouldForwardProp })<{ $glued: boolean }>`
  width: 100%;

  /* A bit weird to have an height, but in Figma the height set to 24 px and not to hug, and the content is 20px
  So we would need to have a 2px bigger margin to match the design, but Mike is not comfortable with that
  So he prefers to have a 24px height and the content centered */
  height: 24px;

  justify-content: ${({ $glued }) => ($glued ? 'flex-start' : 'space-between')};
  align-items: center;
  gap: ${spacing['30']};
`
